import colors from '../styles/abstracts/colors.module.scss'

const WarningIconTriangle = props => (
  <svg {...props} viewBox='0 0 30 27' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Warning Icon</title>
    <desc>A red triangle with an exclamation point in it</desc>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='icons/warning' fill={colors.everplansRed}>
        <path d='M16.1543688,21.7499986 C16.4576297,21.4434769 16.6304557,21.0260856 16.6304557,20.5956508 C16.6304557,20.1652161 16.4576297,19.7445639 16.1543688,19.4413031 C15.8478471,19.1380422 15.4301297,18.9652162 15.0000211,18.9652162 C14.5695863,18.9652162 14.1489342,19.1380422 13.8456733,19.4413031 C13.5424125,19.7445639 13.3695864,20.1652161 13.3695864,20.5956508 C13.3695864,21.0260856 13.5424125,21.4434769 13.8456733,21.7499986 C14.1489342,22.0532594 14.5695863,22.2260855 15.0000211,22.2260855 C15.4301297,22.2260855 15.851108,22.0532594 16.1543688,21.7499986 L16.1543688,21.7499986 Z M16.3043688,16.3340207 L16.3043688,10.2671732 C16.3043688,9.5468472 15.7203471,8.9628255 15.0000211,8.9628255 C14.279695,8.9628255 13.6956733,9.5468472 13.6956733,10.2671732 L13.6956733,16.3340207 C13.6956733,17.0543467 14.279695,17.6383684 15.0000211,17.6383684 C15.7203471,17.6383684 16.3043688,17.0543467 16.3043688,16.3340207 L16.3043688,16.3340207 Z M3.54458703,24.1304332 L26.4554551,24.1304332 L15.0000211,3.94695626 L3.54458703,24.1304332 Z M28.6956723,26.7391287 L1.30436979,26.7391287 C0.84002199,26.7391287 0.410891583,26.4922809 0.177413338,26.0911939 C-0.0563909947,25.690107 -0.0593257771,25.194781 0.169913338,24.7910853 L13.8655646,0.660652131 C14.0970863,0.252391288 14.530782,0 15.0000211,0 C15.4692602,0 15.9029558,0.252391288 16.1341514,0.660652131 L29.8298027,24.7910853 C30.0593679,25.194781 30.0564331,25.690107 29.8226288,26.0911939 C29.5891505,26.4922809 29.1600201,26.7391287 28.6956723,26.7391287 L28.6956723,26.7391287 Z' id='Fill-1' />
      </g>
    </g>
  </svg>
)

WarningIconTriangle.defaultProps = {
  height: '50px',
  width: '50px'
}

export default WarningIconTriangle
