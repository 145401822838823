import PropTypes from 'prop-types'
import {Component} from 'react'

import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import Modals from '../../../core/modals/Modals'
import Closer from '../../../core/closer/Closer'
import ButtonGroup from '../../../core/buttons/ButtonGroup'
import DeleteIcon from '../../../../shared_assets/v2/DeleteIcon'
import {Cancel, Remove} from '../../../../dashboard/src/components/blocks/Buttons'
import WarningIconTriangle from '../../../../dashboard/assets/WarningIconTriangle'

export default class RemoveFile extends Component {
  constructor() {
    super()
    this.state = {processing: false, showModal: false}

    this.closer = this.closer.bind(this)
    this.delete = this.delete.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onDeleteClick = this.onDeleteClick.bind(this)
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  onDeleteClick() {
    this.props.virusFound ? this.props.removeFile(this.props.id) : this.setState({showModal: !this.state.showModal})
  }

  delete() {
    this.setState({processing: true})
    this.props.removeFile(this.props.id)
  }

  render() {
    const {name, isViewMode} = this.props

    if (this.state.showModal) {
      return (
        <Modals.WarningPopUpModal closerComponent={this.closer} showModal={this.state.showModal}>
          <header className='confirm-delete-header'>
            <WarningIconTriangle className='warning-icon' />
            <h2>Are you sure you want to delete this file from your Everplan?</h2>
          </header>
          <TextGutterMedium>This file will no longer be available in your Everplan.</TextGutterMedium>
          <ButtonGroup>
            <Cancel onClick={this.toggleModal} />
            <Remove onClick={this.delete} processing={this.state.processing}>Yes, Delete</Remove>
          </ButtonGroup>
        </Modals.WarningPopUpModal>
      )
    } else {
      return (!isViewMode && <DeleteIcon alt={`Delete ${name}`} onClick={this.onDeleteClick} className='delete-file' />)
    }
  }
}

RemoveFile.propTypes = {
  id: PropTypes.string,
  isViewMode: PropTypes.bool,
  name: PropTypes.string,
  removeFile: PropTypes.func,
  virusFound: PropTypes.bool
}
